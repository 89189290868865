.mainContent {
  background-color: var(--muted-color-ojb);
  min-height: calc(100vh - var(--height-header));
  padding: 80px;
}

@media (width <=1050px) {
  .mainContent {
    padding: 30px 10px;
  }
}
