.expedientDetail {
  display: grid;
  gap: 20px;
  grid-template-columns: 35% 1fr;
}

.expedientDetail_key {
  font-weight: 700;
  text-align: right;
}

.expedientDetail_key,
.expedientDetail_value {
  background-color: var(--muted-color-ojb);
  border-radius: 10px;
  padding: 3px 10px;
}

@media (width <=1050px) {
  .expedientDetail {
    gap: 5px;
  }
}