.collapsibleControl {
  --size-collapsible-control: 35px;
}

.collapsibleControl {
  border: solid var(--primary-color-ojb) 3px;
  border-radius: 10px;
  height: var(--size-collapsible-control);
  width: var(--size-collapsible-control);
  cursor: pointer;
  color: var(--primary-color-ojb);
  display: flex;
  align-items: center;
  justify-content: center;
}