/* The following variables will have an ojb (Online Judgment Bridge) suffix so as not to affect the global variables of the rest of the application */

:root {
  /* Colors */
  --primary-color-ojb: #FB8E2F;
  --secondary-color-ojb: #165144;
  --muted-color-ojb: #F5F5F5;
  --muted-color-1-ojb: #E6E6E6;
  --empty-color-ojb: #FFF;

  /* Sizing */
  --height-header: 115px;
}