.responsiveHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}

.responsiveHeader_portrait {
  transform: translate(-100%);
  transition: transform 0.5s;
  overflow: hidden;
}

@media (width <=1050px) {
  .responsiveHeader[data-is-collapsible-active="true"] .responsiveHeader_portrait {
    transform: translate(0);
  }
}