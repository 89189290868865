.primaryCard {
  --size-icon: 150px;
  --height-button: 45px;
}

.primaryCard {
  background-color: var(--secondary-color-ojb);
  color: var(--empty-color-ojb);
  display: flex;
  flex-direction: column;
  padding: 112px 32px;
  gap: 20px;
  max-width: 500px;
  flex-grow: 1;
  border-radius: 30px;
  position: relative;
  margin-top: calc(var(--size-icon) / 2);
  margin-bottom: calc(var(--height-button)/2);
}

.primaryCard_icon {
  border: solid 15px var(--muted-color-1-ojb);
  background-color: var(--primary-color-ojb);
  height: var(--size-icon);
  width: var(--size-icon);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--empty-color-ojb);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 71px;
}

.primaryCard_content {
  text-align: center;
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}

.primaryCard_downloadButton {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;
}