.expedientDetails {
  background-color: var(--empty-color-ojb);
  border-radius: 40px;
  padding: 80px;
  max-width: 800px;
}

.expedientDetails_title {
  color: var(--secondary-color-ojb);
  text-align: center;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
}

.expedientDetails_details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media (width <=1050px) {
  .expedientDetails {
    padding: 60px 20px;
  }


  .expedientDetails_title {
    font-size: 20px;
  }
}