.navBar {
  display: flex;
  gap: 48px;
  color: var(--empty-color-ojb);
}

.navBar_item {
  text-align: center;
  display: flex;
  align-items: center;
}