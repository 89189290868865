.header {
  height: var(--height-header);
  background-color: var(--secondary-color-ojb);
  padding: 0 40px;
  display: flex;
  align-items: center;
  gap: 48px;
  justify-content: space-between;
}

.header.header[data-orientation="portrait"] {
  position: fixed;
  top: var(--height-header);
  width: 100%;
}

.header_logoImageWrapper {
  height: 47px;
}

.header_logoImage {
  display: block;
  height: 100%;
}

.header_collapsibleControl {
  display: none;
}

@media (width <=1050px) {

  .header[data-orientation="landscape"] .header_navBar,
  .header[data-orientation="landscape"] .registerLoginButtons {
    display: none;
  }

  .header[data-orientation="landscape"] .header_collapsibleControl {
    display: unset;
  }


  .header[data-orientation="portrait"] {
    height: calc(100vh - var(--height-header));
    padding: 40px;
    flex-direction: column;
    align-items: stretch;
    gap: 48px;
    justify-content: space-between;
  }

  .header[data-orientation="portrait"] .header_logoImageWrapper {
    display: none;
  }

  .header[data-orientation="portrait"] .navBar,
  .header[data-orientation="portrait"] .registerLoginButtons>div {
    flex-direction: column;
    gap: 30;
  }

  .header[data-orientation="portrait"] .registerLoginButtons a {
    display: flex;
    flex-direction: column;
  }

  .header[data-orientation="portrait"] .registerLoginButtons button {
    align-self: stretch;
  }
}
