.ExpedientDetailsWrapper,
.primaryCardWrapper {
  display: flex;
  justify-content: center;
}

.primaryCardWrapper {
  padding-top: 50px;
}

.ExpedientDetailsWrapper>div {
  flex-basis: 100%;
}